<template>
    <div class="map">
        <div class="haerbin">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name_ha">Ha Er Bin</div>
        </div>
        <div class="changchun">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Chang Chun</div>
        </div>
        <div class="shenyang">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Shen Yang</div>
        </div>
        <div class="dalian">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Da Lian</div>
        </div>
        <div class="beijing">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Bei Jing</div>
        </div>
        <div class="tianjin">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Tian Jin</div>
        </div>
        <div class="jinan">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Ji Nan</div>
        </div>
        <div class="xuzhou">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name name_xu">Xu Zhou</div>
        </div>
        <div class="hefei">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name_he">He Fei</div>
        </div>
        <div class="nanjing">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name_nan">Nan Jing</div>
        </div>
        <div class="zhenjiang">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name_zhen">Zhen Jiang</div>
        </div>
        <div class="changzhou">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name_chang">Chang Zhou</div>
        </div>
        <div class="wuxi">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name_wu">Xu Xi</div>
        </div>
        <div class="shanghai">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Shang Hai</div>
        </div>
        <div class="ningbo">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Ning Bo</div>
        </div>
        <div class="xiamen">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Xia Men</div>
        </div>
        <div class="shenzhen">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Shen Zhen</div>
        </div>
        <div class="guangzhou">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name_guang">Guang Zhou</div>
        </div>
        <div class="wuhan">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Wu Han</div>
        </div>
        <div class="chongqing">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Chong Qing</div>
        </div>
        <div class="chengdu">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Cheng Du</div>
        </div>
        <div class="shijiazhuang">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name_ha name_shi">Shi Jia Zhuang</div>
        </div>
        <div class="zhengzhou">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Zheng Zhou</div>
        </div>
        <div class="xian">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Xi An</div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style lang="scss">
.map {
    position: relative;
    margin-top: 15px;
    width: 806px;
    height: 605px;
    background: url('../assets/images/index/map.png') no-repeat center center;
    background-size: cover;
    .dotted {
        width: 8px;
        height: 8px;
        background-color: #25a9e0;
        border-radius: 50%;
    }
    div[class^='purple'] {
        position: absolute;
        width: 8px;
        height: 8px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        box-shadow: 0 0 6px #25a9e0;
    }
    div[class^='name'] {
        font-size: 12px;
    }
    .purple1 {
        animation: suo 1.5s linear 0s infinite;
    }
    .purple2 {
        animation: suo 1.5s linear 0.8s infinite;
    }
    .purple3 {
        animation: suo 1.5s linear 1.2s infinite;
    }
    .name {
        position: absolute;
        top: -80%;
        left: 160%;
        width: 80px;
        color: #25a9e0;
        font-weight: 400;
    }
    .name_ha {
        position: absolute;
        top: -80%;
        left: 160%;
        width: 60px;
        color: #25a9e0;
        font-weight: 400;
    }
    .name_he {
        position: absolute;
        top: -230%;
        left: -270%;
        width: 45px;
        color: #25a9e0;
        font-weight: 400; 
    }
    .name_nan{
        position: absolute;
        top: -230%;
        left: -111%;
        width: 45px;
        color: #25a9e0;
        font-weight: 400; 
    }
    .name_zhen{
        position: absolute;
        top: -230%;
        left: -111%;
        width: 45px;
        color: #25a9e0;
        font-weight: 400; 
    }
    .name_chang {
        position: absolute;
        top: -215%;
        left: 107%;
        width: 45px;
        color: #25a9e0;
        font-weight: 400;
    }
    .name_wu {
        position: absolute;
        bottom: -200%;
        left: -206%;
        width: 45px;
        color: #25a9e0;
        font-weight: 400;
    }
    .name_guang {
        position: absolute;
        top: -200%;
        left: -206%;
        width: 74px;
        color: #25a9e0;
        font-weight: 400;
    }
    /* 区域地点 */
    // 哈尔滨
    .haerbin {
        position: absolute;
        right: 96px;
        top: 119px;
    }
    // 长春
    .changchun {
        position: absolute;
        right: 110px;
        top: 155px;
    }
    // 沈阳
    .shenyang {
        position: absolute;
        right: 121px;
        top: 198px;
    }
    // 大连
    .dalian {
        position: absolute;
        right: 126px;
        top: 230px;
    }
    // 北京
    .beijing {
        position: absolute;
        right: 212px;
        top: 233px;
    }
    // 天津
    .tianjin {
        position: absolute;
        right: 191px;
        top: 255px;
    }
    // 济南
    .jinan {
        position: absolute;
        right: 191px;
        top: 298px;
    }
    // 徐州
    .xuzhou {
        position: absolute;
        right: 181px;
        top: 344px;
    }
    // 合肥
    .hefei {
        position: absolute;
        right: 173px;
        top: 384px;
    }
    // 南京
    .nanjing {
        position: absolute;
        right: 151px;
        top: 376px;
    }
    // 镇江
    .zhenjiang {
        position: absolute;
        right: 130px;
        top: 361px;
    }
    // 常州
    .changzhou {
        position: absolute;
        right: 121px;
        top: 364px;
    }
    // 上海
    .shanghai {
        position: absolute;
        right: 105px;
        top: 371px;
    }
    // 无锡
    .wuxi {
        position: absolute;
        right: 114px;
        top: 369px;
    }
    // 宁波
    .ningbo {
        position: absolute;
        right: 105px;
        top: 391px;
    }
    // 厦门
    .xiamen {
        position: absolute;
        right: 144px;
        bottom: 113px;
    }
    // 深圳
    .shenzhen {
        position: absolute;
        right: 211px;
        bottom: 76px;
    }
    // 广州
    .guangzhou {
        position: absolute;
        right: 226px;
        bottom: 86px;
    }
    // 武汉
    .wuhan {
        position: absolute;
        right: 217px;
        bottom: 195px;
    }
    // 重庆
    .chongqing {
        position: absolute;
        right: 315px;
        bottom: 182px;
    }
    // 成都
    .chengdu {
        position: absolute;
        right: 375px;
        bottom: 188px;
    }
    // 石家庄
    .shijiazhuang {
        position: absolute;
        right: 223px;
        top: 267px;
    }
    // 郑州
    .zhengzhou {
        position: absolute;
        right: 225px;
        top: 317px;
    }
    // 西安
    .xian {
        position: absolute;
        right: 292px;
        top: 322px;
    }
}
@keyframes suo {
    0% {}
    70% {
        width: 40px;
        height: 40px;
        opacity: 1;
    }
    100% {
        width: 60px;
        height: 60px;
        opacity: 0;
    }
}
@media (max-width: 768px) {
    .map {
    position: relative;
    margin-top: 0;
    // width: 806px;
    height: 6rem;
    background: url('../assets/images/index/map.png') no-repeat center center;
    background-size: contain;
    .dotted {
        width: 4px;
        height: 4px;
        background-color: #25a9e0;
        border-radius: 50%;
    }
    div[class^='purple'] {
        position: absolute;
        width: 3px;
        height: 3px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        box-shadow: 0 0 4px #25a9e0;
    }
    .purple1 {
        animation: suo 1.5s linear 0s infinite;
    }
    .purple2 {
        animation: suo 1.5s linear 0.8s infinite;
    }
    .purple3 {
        animation: suo 1.5s linear 1.2s infinite;
    }
    div[class^='name'] {
        font-size: 12px;
    }
    .name {
        position: absolute;
        top: -80%;
        left: 160%;
        width: 30px;
        color: #25a9e0;
        font-weight: 400;
    }
    .name_ha {
        position: absolute;
        top: -80%;
        left: 160%;
        width: 45px;
        color: #25a9e0;
        font-weight: 400;
    }
    .name_he {
        position: absolute;
        top: -230%;
        left: -0.5rem;
        width: 45px;
        color: #25a9e0;
        font-weight: 400; 
    }
    .name_nan{
        position: absolute;
        top: -230%;
        left: -111%;
        width: 45px;
        color: #25a9e0;
        font-weight: 400; 
    }
    .name_zhen{
        position: absolute;
        top: -230%;
        left: -0.5rem;
        width: 45px;
        color: #25a9e0;
        font-weight: 400; 
    }
    .name_chang {
        position: absolute;
        top: -390%;
        left: 107%;
        width: 45px;
        color: #25a9e0;
        font-weight: 400;
    }
    .name_wu {
        position: absolute;
        bottom: -200%;
        left: -206%;
        width: 45px;
        color: #25a9e0;
        font-weight: 400;
    }
    .name_guang {
        position: absolute;
        top: -200%;
        left: -206%;
        width: 45px;
        color: #25a9e0;
        font-weight: 400;
    }
    .name_shi {
        position: absolute;
        top: -80%;
        left: -0.7rem;
        width: 80px;
        color: #25a9e0;
        font-weight: 400;
    }
    .name_xu {
        position: absolute;
        top: -300%;
        left: 0;
        width: 45px;
        color: #25a9e0;
        font-weight: 400; 
    }
    /* 区域地点 */
    // 哈尔滨
    .haerbin {
        position: absolute;
        right: 0.8rem;
        top: 1.4rem;
    }
    // 长春
    .changchun {
        position: absolute;
        right: 0.85rem;
        top: 1.7rem;
    }
    // 沈阳
    .shenyang {
        position: absolute;
        right: 0.95rem;
        top: 2rem;
    }
    // 大连
    .dalian {
        position: absolute;
        right: 1.01rem;
        top: 2.3rem;
    }
    // 北京
    .beijing {
        position: absolute;
        right: 1.8rem;
        top: 2.3rem;
    }
    // 天津
    .tianjin {
        position: absolute;
        right: 1.65rem;
        top: 2.5rem;
    }
    // 济南
    .jinan {
        position: absolute;
        right: 1.65rem;
        top: 2.8rem;
    }
    // 徐州
    .xuzhou {
        position: absolute;
        right: 1.5rem;
        top: 3.2rem;
    }
    // 合肥
    .hefei {
        position: absolute;
        right: 1.5rem;
        top: 3.7rem;
    }
    // 南京
    .nanjing {
        position: absolute;
        right: 1.5rem;
        top: 3.6rem;
    }
    // 镇江
    .zhenjiang {
        position: absolute;
        right: 1.2rem;
        top: 3.4rem;
    }
    // 常州
    .changzhou {
        position: absolute;
        right: 1.1rem;
        top: 3.45rem;
    }
    // 上海
    .shanghai {
        position: absolute;
        right: 0.9rem;
        top: 3.55rem;
    }
    // 无锡
    .wuxi {
        position: absolute;
        right: 1rem;
        top: 3.45rem;
    }
    // 宁波
    .ningbo {
        position: absolute;
        right: 0.9rem;
        top: 3.78rem;
    }
    // 厦门
    .xiamen {
        position: absolute;
        right: 1.3rem;
        bottom: 1.35rem;
    }
    // 深圳
    .shenzhen {
        position: absolute;
        right: 1.85rem;
        bottom: 1rem;
    }
    // 广州
    .guangzhou {
        position: absolute;
        right: 2rem;
        bottom: 1.1rem;
    }
    // 武汉
    .wuhan {
        position: absolute;
        right: 2.1rem;
        bottom: 2.1rem;
    }
    // 重庆
    .chongqing {
        position: absolute;
        right: 2.8rem;
        bottom: 1.9rem;
    }
    // 成都
    .chengdu {
        position: absolute;
        right: 3.35rem;
        bottom: 2rem;
    }
    // 石家庄
    .shijiazhuang {
        position: absolute;
        right: 1.9rem;
        top: 2.6rem;
    }
    // 郑州
    .zhengzhou {
        position: absolute;
        right: 2rem;
        top: 2.9rem;
    }
    // 西安
    .xian {
        position: absolute;
        right: 2.5rem;
        top: 3rem;
    }
}
}
</style>